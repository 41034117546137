/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-01-21 16:31:01
 * @LastEditors: AKJERRT 1617572569@qq.com
 * @LastEditTime: 2022-07-06 14:54:52
 */
export const distributorColums = [

  {
    title: '大区',
    dataIndex: 'areaName',
    key: 'areaName',
    align: 'center',
  },
  {
    title: '事务所',
    dataIndex: 'firmName',
    key: 'firmName',
    align: 'center',
  },
  {
    title: '经销商',
    dataIndex: 'dealerName',
    key: 'dealerName',
    align: 'center',
    scopedSlots: {
      customRender: 'dealerName'
    }
  },
  {
    title: '总额度',
    dataIndex: 'limitTotal',
    key: 'limitTotal',
    align: 'center',
    scopedSlots: {
      customRender: 'amount'
    }
  },
  {
    title: '已用额度',
    dataIndex: 'usedLimitTotal',
    key: 'usedLimitTotal',
    align: 'center',
    scopedSlots: {
      customRender: 'amount'
    }
  },
  {
    title: '逾期额度',
    dataIndex: 'overdueLimitTotal',
    key: 'overdueLimitTotal',
    align: 'center',
    scopedSlots: {
      customRender: 'amount'
    }
  },
  {
    title: '可用额度',
    dataIndex: 'canUsedLimit',
    key: 'canUsedLimit',
    align: 'center',
    scopedSlots: {
      customRender: 'amount'
    }
  },
]
