<template>
  <a-spin :spinning="spinning">
    <a-card :border="false">
      <div class="realtor">
        <!-- 查询表单 -->
        <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
          <a-row :gutter="8">
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="大区事务所">
                <a-cascader
                  :options="dealerList"
                  v-model="regionalOffice"
                  change-on-select
                  placeholder="请选择大区/事务所"
                  @change="onChange"
                  :field-names="{ label: 'title', value: 'id', children: 'children' }"
                />
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="经销商">
                <a-input v-model="searchData.dealerName" placeholder="请输入经销商名称" allowClear></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="5" :md="8" sm="2">
              <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
                <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
                <a-button type="default" @click="reset()" icon="sync">重置</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <div>
          <a-button v-if="isCheck" type="primary" @click="toHandler('check')">查看</a-button>
          <a-button v-if="isRecord" type="primary" @click="toHandler('checkRecord')">使用流水</a-button>
          <a-button v-if="isRepayment" type="primary" @click="toHandler('still')" icon="redo">还款</a-button>
          <!--          <downLoad-->
          <!--            method="get"-->
          <!--            api="/api/order/order/orderInfo/exportSingleOrderInfo"-->
          <!--            :params="{ id: 11 }"-->
          <!--            name="导出.xlsx"-->
          <!--            >导出</downLoad-->
          <!--          >-->
        </div>
        <!-- 数据表格 -->
        <!-- :pagination="page" -->
        <a-table
          :loading="tableLoading"
          :row-selection="rowSelection"
          :rowKey="(record) => record.id"
          :columns="columns"
          :data-source="tableData"
          bordered
          @change="changeTable"
          :customRow="changeTableRow"
          :pagination="page"
        >
          <span slot="amount" slot-scope="text"> {{ text | formatMoney }} </span>
          <span slot="dealerName" slot-scope="text, row"
            ><a @click="onCheckDealerInfo(row)">{{ text }}</a>
          </span>
        </a-table>
        <!-- 新增 -->
        <FinanceCreditModal ref="FinanceCreditModal"></FinanceCreditModal>
        <!-- 还款 -->
        <RepaymentModal ref="RepaymentModal"></RepaymentModal>
        <!-- 查看 -->
        <SingleUseModal ref="SingleUseModal"></SingleUseModal>
        <!-- 使用记录 -->
        <LimitInfoPayDetailed ref="LimitInfoPayDetailed"></LimitInfoPayDetailed>

        <CustomerInfoCheckModal ref="CustomerInfoCheckModal"></CustomerInfoCheckModal>
      </div>
    </a-card>
  </a-spin>
</template>

<script>
import { distributorColums } from './thead/distributorColums'
import FinanceCreditModal from './components/FinanceCreditModal.vue'
import RepaymentModal from './components/RepaymentModal.vue'
import SingleUseModal from './components/SingleUseModal'
import LimitInfoPayDetailed from './components/LimitInfoPayDetailed'
import CustomerInfoCheckModal from '../customer/components/CustomerInfoCheckModal.vue'

import { listDealerLimit, repaymentAll, rollbackStatus } from './api/LimitInfoApi'
import { checkPermission } from '@/utils/permissions'
import { baseURL } from '@/utils/request'
import { PAYMENT_TEMP } from '@/utils/downloadTempHref'
import DealerRebatePayDetailed from '@/views/rebate_total/DealerRebatePayDetailed'
export default {
  name: 'financeReceipt',
  components: {
    FinanceCreditModal,
    RepaymentModal,
    SingleUseModal,
    LimitInfoPayDetailed,
    CustomerInfoCheckModal,
  },
  data() {
    return {
      moneyType: ['全部商品', '指定类型', '指定商品'],
      repaymentStatus: ['正常', '即将到期', '逾期', '已过期'],
      columns: distributorColums,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      selectedRows: [],
      confirmLoading: false,
      visible: false,
      visibleAll: false,
      loading: false,
      loadingAll: false,
      rejectReason: '',
      verifyRemark: '',
      dealerList: [],
      regionalOffice: [],
      dataList: [],
      //上传的数据
      uploadData: [],
      //文件数据
      fileList: [],
      //上传文件地址
      uploadUrl: baseURL + '/api/dealer/dealer/dealerPaymentFrom/excelReader',
      //上传文件请求头
      headers: {
        'Authorization-Admin': this.$store.getters.token,
      },
      downloadTempHref: PAYMENT_TEMP,
      spinning: false,
      isCheck: checkPermission('dealer:limit:info:check'),
      isRecord: checkPermission('dealer:limit:info:record'),
      isRepayment: checkPermission('dealer:limit:info:repayment'),
      distributor_name: this.$route.params.dealerName,
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
    getToken() {
      return this.$sotre.state.SET_TOKEN()
    },
  },
  mounted() {
    if (this.distributor_name) {
      this.searchData.dealerName = this.distributor_name
    }
    this.getData()
  },
  methods: {
    onCheckDealerInfo(row) {
      const _this = this
      _this.$refs.CustomerInfoCheckModal.setRowData(row, 'check')
    },
    toHandler(name) {
      const _this = this
      if (_this.selectedRows.length === 0 || _this.selectedRows.length > 1) {
        return _this.$message.warning('请选择一条记录')
      }
      if (name === 'check') {
        if (_this.selectedRows.length === 1) {
          _this.$refs.SingleUseModal.setRowData(this.selectedRows[0])
          return
        } else {
          _this.$message.warning('请选择一条记录')
        }
      }
      if (name === 'checkRecord') {
        if (_this.selectedRows.length === 1) {
          _this.$refs.LimitInfoPayDetailed.setRowData(this.selectedRows[0])
          return
        } else {
          _this.$message.warning('请选择一条记录')
        }
      }
      if (name === 'still') {
        if (_this.selectedRows.length === 1) {
          const everyResult = this.selectedRows.every((item) => {
            return item.usedLimitTotal > 0
          })
          if (everyResult) {
            _this.$confirm({
              title: '还款警告',
              content: '确认要一键全部还款吗?',
              okText: '确认',
              cancelText: '取消',
              async onOk() {
                console.log('id >>>', _this.selectedRows)
                const data = {
                  dealerId: _this.selectedRows[0].id,
                }
                const res = await repaymentAll(data)
                if (res.code === 200) {
                  _this.$message.success(res.message)
                  _this.getData()
                } else {
                  _this.$message.error(res.message)
                }
              },
              onCancel() {},
            })
            return
          } else {
            this.$message.warning('请选择已使用额度的经销商')
          }
        } else {
          _this.$message.warning('请选择一条记录')
        }
      }
      switch (name) {
        case 'export':
          window.location.href = `${process.env.VUE_APP_API_BASE_URL}/api/customer/customer/financeReceipt/excel=${this.selectedRowKeys[0]}`
          break
      }
    },

    /**
     *
     *
     *
     *
     *
     */
    downLoadTemp() {
      window.open(this.downloadTempHref, '_blank')
    },
    startDownLoad() {
      this.spinning = true
    },
    downLoadDone() {
      this.spinning = false
    },
    //导入
    uploadChange(info) {
      this.tableLoading = true
      setTimeout(() => {
        if (info.file.response) {
          let hint = info.file.response
          if (hint.code === 200) {
            this.tableLoading = false
            this.$message.success(hint.message)
            this.getData()
          } else if (hint.code === 500) {
            this.tableLoading = false
            this.$message.error(hint.message)
          }
          if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList)
          }
        }
      })
    },
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true
      this.selectedRowKeys = []
      this.selectedRows = []
      listDealerLimit({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))

      this.axios.post('/api/firm/mhiac/mhiacFirm/getTree').then((res) => {
        // 获取大区事务所经销商数据
        this.dealerList = res.body
      })
    },

    onChange(val) {
      this.searchData.areaId = val[0]
      this.searchData.firmId = val[1]
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.regionalOffice = []
      this.page.current = 1
      this.page.pageSize = 10
      this.loadingAll = false
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #e9e9e9;
}
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}
::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}
</style>
