<template>
  <a-modal
    style="top: 8px"
    width="80%"
    v-model="visible"
    :footer="null"
    :title="title+'详情'"
    :maskClosable="false"
  >
    <!-- 查询表单 -->
<!--    <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">-->
<!--      <a-row :gutter="8">-->
<!--        <a-col :md="8" :xl="8" :xxl="8" :sm="12">-->
<!--          <a-form-model-item label="单号">-->
<!--            <a-input v-model="searchData.dealNo" placeholder="请输入关联单号"></a-input>-->
<!--          </a-form-model-item>-->
<!--        </a-col>-->
<!--        <a-col :md="8" :xl="8" :xxl="8" :sm="12">-->
<!--          <a-form-model-item label="交易类型">-->
<!--            <a-select :default-value="1">-->
<!--              <a-select-option :value="1"> 支付 </a-select-option>-->
<!--              <a-select-option :value="2"> 批付 </a-select-option>-->
<!--            </a-select>-->
<!--          </a-form-model-item>-->
<!--        </a-col>-->
<!--        <a-col :md="8" :xl="8" :xxl="8" :sm="12">-->
<!--          <a-form-model-item label="金额类型">-->
<!--            <a-select :default-value="1">-->
<!--              <a-select-option :value="1"> 全部商品 </a-select-option>-->
<!--              <a-select-option :value="2"> 指定类型 </a-select-option>-->
<!--              <a-select-option :value="3"> 指定商品 </a-select-option>-->
<!--            </a-select>-->
<!--          </a-form-model-item>-->
<!--        </a-col>-->
<!--        <a-col :md="8" :xl="8" :xxl="8" :sm="12">-->
<!--          <a-form-model-item label="创建时间">-->
<!--            <DateRange :startTime.sync="searchData.startDate" :endTime.sync="searchData.endDate"></DateRange>-->
<!--          </a-form-model-item>-->
<!--        </a-col>-->
<!--        <a-col :xxl="6" :xl="5" :md="8" sm="2">-->
<!--          <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">-->
<!--            <a-button type="primary" @click="getList()" icon="search">查询</a-button>-->
<!--            <a-button type="default" @click="reset()" icon="sync">重置</a-button>-->
<!--          </a-form-model-item>-->
<!--        </a-col>-->
<!--      </a-row>-->
<!--    </a-form-model>-->
    <a-divider orientation="left"> 客户信息 </a-divider>
    <a-descriptions :column="3">
      <a-descriptions-item label="大区"> {{ rowDetail.bigAreaName }} </a-descriptions-item>
      <a-descriptions-item label="事务所"> {{ rowDetail.firmName }} </a-descriptions-item>
      <a-descriptions-item label="经销商"> {{ rowDetail.dealerName }} </a-descriptions-item>
      <a-descriptions-item label="创建人"> {{ rowDetail.createUser }} </a-descriptions-item>
      <a-descriptions-item label="创建时间"> {{ rowDetail.createTime }} </a-descriptions-item>
    </a-descriptions>
    <a-divider orientation="left"> 金额信息 </a-divider>
    <a-descriptions :column="4">
      <a-descriptions-item label="逾期金额"> <a-tag color="pink"> ￥{{ rowDetail.usedAmount | formatMoney }} </a-tag></a-descriptions-item>
      <a-descriptions-item label="逾期天数"> <a-tag color="pink"> {{ rowDetail.overdueDays }}天 </a-tag> </a-descriptions-item>
      <a-descriptions-item label="有效时间"> {{ rowDetail.validityStart }} ~ {{ rowDetail.validityEnd }} </a-descriptions-item>
      <a-descriptions-item label="金额类型"> {{ rowDetail.limitType == 1 ? '全部商品' : rowDetail.limitType == 2 ? '商品分类' : '指定商品' }} </a-descriptions-item>
    </a-descriptions>
    <a-divider orientation="left"> 额度信息 </a-divider>
    <a-descriptions :column="4">
<!--      <a-descriptions-item label="流水号"> LSH873284395605 </a-descriptions-item>-->
      <a-descriptions-item label="额度名称"> <a-tag color="green"> {{ rowDetail.name }} </a-tag> </a-descriptions-item>
      <a-descriptions-item label="总额度"> ￥{{ rowDetail.limitAmount | formatMoney }} </a-descriptions-item>
      <a-descriptions-item label="已用额度"> ￥{{ rowDetail.usedAmount | formatMoney }} </a-descriptions-item>
      <a-descriptions-item label="逾期额度"> ￥{{ rowDetail.usedAmount | formatMoney }} </a-descriptions-item>
      <a-descriptions-item label="可用额度"> ￥{{ rowDetail.remainingAmount | formatMoney }} </a-descriptions-item>
    </a-descriptions>

    <div style="text-align: right; margin-top: 14px">
      <a-button type="default" @click="toCancel">关闭</a-button>
    </div>
  </a-modal>
</template>

<script>
const qs = require('qs')

export default {
  name: 'SingleUseOverModal',
  components: {},
  data() {
    return {
      visible: false,
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      selectedRows: [],
      title: '',
      rowDetail: {}
    }
  },
  methods: {
    // 查询
    getList() {
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },

    // 重置
    reset() {
      this.page.current = 1
      this.page.pageSize = 10
      this.searchData = {}
      this.regionalOffice = []
      this.getData()
    },
    setRowData(row) {
      this.visible = true
      this.title = row.name
      this.rowDetail = row
    },
    // table分页
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },

    toCancel() {
      this.visible = false
    },
  },
}
</script>

<style lang="less" scoped>
::v-deep .ant-descriptions-row .ant-descriptions-item .ant-descriptions-item-label {
  width: 6.25rem;
  text-align: right;
}
</style>