<template>
  <a-modal
    style="top: 8px"
    width="80%"
    v-model="visible"
    :footer="null"
    :title="'经销商额度详情'"
    :maskClosable="false"
  >
    <!-- 查询表单 -->
    <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
      <a-row :gutter="8">
<!--        <a-col :md="8" :xl="8" :xxl="8" :sm="12">-->
<!--          <a-form-model-item label="单号">-->
<!--            <a-input v-model="searchData.dealNo" placeholder="请输入关联单号"></a-input>-->
<!--          </a-form-model-item>-->
<!--        </a-col>-->
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-item label="额度类型">
            <a-select placeholder="请选择" v-model="searchData.limitType" allowClear>
              <a-select-option :value="1">全部商品</a-select-option>
              <a-select-option :value="2">商品分类</a-select-option>
              <a-select-option :value="3">指定商品</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :md="8" :xl="8" :xxl="8" :sm="12">
          <a-form-model-item label="有效时间">
            <DateRange :startTime.sync="searchData.startTime" :endTime.sync="searchData.endTime"></DateRange>
          </a-form-model-item>
        </a-col>
        <a-col :xxl="6" :xl="5" :md="8" sm="2">
          <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
            <a-button type="primary" @click="getList()" icon="search">查询</a-button>
            <a-button type="default" @click="reset()" icon="sync">重置</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-divider orientation="left"> 客户信息 </a-divider>
    <a-descriptions :column="3">
      <a-descriptions-item label="大区"> {{ rowDetail.areaName }} </a-descriptions-item>
      <a-descriptions-item label="事务所"> {{ rowDetail.firmName }} </a-descriptions-item>
      <a-descriptions-item label="经销商"> {{ rowDetail.dealerName }} </a-descriptions-item>
    </a-descriptions>
    <a-divider orientation="left"> 额度信息 </a-divider>
    <a-descriptions :column="4">
      <a-descriptions-item label="总额度"> ￥{{ rowDetail.limitTotal | formatMoney }} </a-descriptions-item>
      <a-descriptions-item label="已用额度"> ￥{{ rowDetail.usedLimitTotal | formatMoney }} </a-descriptions-item>
      <a-descriptions-item label="逾期额度"> ￥{{ rowDetail.overdueLimitTotal | formatMoney }} </a-descriptions-item>
      <a-descriptions-item label="可用额度"> ￥{{ rowDetail.canUsedLimit | formatMoney }} </a-descriptions-item>
    </a-descriptions>
    <a-divider orientation="left"> 额度组成 </a-divider>

    <a-table
      :loading="tableLoading"
      :columns="columns"
      :data-source="tableData"
      @change="changeTable"
      :rowKey="(record) => record.id"
      bordered
      :maskClosable="false"
      :pagination="page"
    >
      <span slot="limitType" slot-scope="text">
            <span v-if="text == 1"><a-tag color="green">全部商品</a-tag></span>
            <span v-if="text == 2"><a-tag color="cyan">商品分类</a-tag></span>
            <span v-if="text == 3"><a-tag color="blue">指定商品</a-tag></span>
          </span>
      <span slot="limitStatus" slot-scope="text">
            <a-tag color="#808080" v-if="text == 0">未开始</a-tag>
            <a-tag color="#e6a23c" v-if="text == 1">使用中</a-tag>
            <a-tag color="#ffc000" v-if="text == 2">即将过期</a-tag>
            <a-tag color="red" v-if="text == 3">已逾期</a-tag>
            <a-tag color="#333333" v-if="text == 4">已过期</a-tag>
          </span>
      <span slot="authStatus" slot-scope="text">
            <a-tag v-if="text == 0" color="#333333">待处理</a-tag>
            <a-tag v-if="text == 1" color="#e6a23c">待审核</a-tag>
            <a-tag v-if="text == 2" color="#108ee9">审核通过</a-tag>
            <a-tag v-if="text == 3" color="#67c23a">审核拒绝</a-tag>
          </span>
      <span slot="amount" slot-scope="text"> {{ text | formatMoney }} </span>
      <span slot="validityStart" slot-scope="text, row"> {{ row.validityStart }} ~ {{ row.validityEnd }} </span>
<!--      <span slot="dealNo" slot-scope="text, row">-->
<!--        <a @click="checkOrderInfo(row.dealNo, row.dealType)">{{ text }}</a>-->
<!--      </span>-->
    </a-table>
    <div style="text-align: right; margin-top: 14px">
      <a-button type="default" @click="toCancel">关闭</a-button>
    </div>
    <SingleUseOverModal ref="SingleUseOverModal" />
    <OrderInfoCheckModal ref="OrderInfoCheckModal"></OrderInfoCheckModal>
    <OrderShipInfoEditModal ref="OrderShipInfoEditModal"></OrderShipInfoEditModal>
  </a-modal>
</template>

<script>
import SingleUseOverModal from './SingleUseOverModal.vue'
import OrderShipInfoEditModal from '@/views/order_ship_info/components/OrderShipInfoEditModal'
import OrderInfoCheckModal from '@/views/order_info/components/OrderInfoCheckModal'
import { listLimitInfo } from '../api/LimitInfoApi'
const qs = require('qs')

export default {
  name: 'DealerBalanceDetailed',
  components: {
    OrderInfoCheckModal,
    OrderShipInfoEditModal,
    SingleUseOverModal,
  },
  data() {
    return {
      columns: [
        {
          title: '额度名称',
          dataIndex: 'name',
          align: 'center',
          key: 'name',
          width: 100,
        },
        {
          title: '额度金额',
          dataIndex: 'limitAmount',
          key: 'limitAmount',
          align: 'center',
          scopedSlots: {
            customRender: 'amount'
          }
        },
        {
          title: '已用额度',
          dataIndex: 'usedAmount',
          align: 'center',
          key: 'usedAmount',
          scopedSlots: { customRender: 'amount' },
        },
        {
          title: '可用额度',
          dataIndex: 'remainingAmount',
          align: 'center',
          key: 'remainingAmount',
          scopedSlots: { customRender: 'amount' },
        },
        {
          title: '额度类型',
          dataIndex: 'limitType',
          key: 'limitType',
          align: 'center',
          scopedSlots: {
            customRender: 'limitType'
          }
        },
        {
          title: '有效时间',
          dataIndex: 'validityStart',
          key: 'validityStart',
          align: 'center',
          scopedSlots: { customRender: 'validityStart' }
        },
        {
          title: '额度状态',
          dataIndex: 'limitStatus',
          key: 'limitStatus',
          align: 'center',
          scopedSlots: { customRender: 'limitStatus' }
        },
        {
          title: '创建人',
          dataIndex: 'createUser',
          key: 'createUser',
          align: 'center',
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime',
          align: 'center',
        },
      ],
      visible: false,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      selectedRows: [],
      dealerId: 0,
      authStatus: 2,
      title: '',
      rowDetail: {}
    }
  },
  methods: {
    // 查询
    getList() {
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },

    // 重置
    reset() {
      this.page.current = 1
      this.page.pageSize = 10
      this.searchData = {}
      this.regionalOffice = []
      this.getData()
    },

    //打开上游单据
    checkOrderInfo() {
      this.$refs.SingleUseOverModal.setRowData()
    },
    setRowData(row) {
      this.visible = true
      this.title = row.dealerName
      this.rowDetail = row
      this.dealerId = row.id
      this.getData()
    },
    getData() {
      this.tableLoading = true
      this.selectedRowKeys = []
      this.selectedRows = []
      this.searchData.dealerId = this.dealerId
      this.searchData.authStatus = 2
      listLimitInfo({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
    },

    // table分页
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },

    toCancel() {
      this.visible = false
    },
  },
}
</script>

<style lang="less" scoped>
::v-deep .ant-descriptions-row .ant-descriptions-item .ant-descriptions-item-label {
  width: 6.25rem;
  text-align: right;
}
</style>